import { LinkIcon } from "@heroicons/react/24/outline";

export interface ResourceType {
  name: string;
  description?: string;
  url: string;
  type: string;
}

const ResourceList = ({
  resources,
  showLink = false,
}: {
  resources: ResourceType[];
  showLink?: boolean;
}) => {
  if (!resources || resources.length === 0) {
    return null;
  }

  return (
    <>
      {resources.map((resource: ResourceType) => (
        <div
          key={resource.type}
          className="border-b bg-white pb-6 last:border-b-0 last:pb-0"
        >
          <div className="mt-4 first:mt-0">
            <h2 className="text-lg font-medium">{resource.name}</h2>
            <p className="mt-1 text-sm text-gray-600">{resource.description}</p>
            <div className="group mt-2 flex items-center justify-between rounded bg-gray-50 p-2 text-sm">
              <div className="flex items-center">
                <LinkIcon
                  strokeWidth={2}
                  className="mr-2 h-5 w-5 text-brand-orange-medium"
                />
                <span className="max-w-xs truncate">
                  <a
                    download
                    href={resource.url}
                    target="_blank"
                    rel="noreferrer"
                    className="text-brand-green-dark hover:text-brand-green-dark hover:underline"
                  >
                    {showLink ? resource.url : "Download"}
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default ResourceList;
