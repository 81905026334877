import { useMutation } from "@apollo/client";
import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";

import { MUTATION_LOGOUT } from "api/mutations/account";
import LogoDark from "components/Image/Logo/Dark";
import Loading from "components/Loading";
import { APP_URLS } from "settings";
import { useUserStore } from "store/User";

const navigation = [
  { name: "Dashboard", href: APP_URLS.DASHBOARD },
  { name: "Cases", href: APP_URLS.CASE_MANAGEMENT },
  { name: "Resources", href: APP_URLS.RESOURCES },
  { name: "Account", href: APP_URLS.ACCOUNT.ACCOUNT_PROFILE },
];

const Header = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [loggingOut, setLoggingOut] = useState<boolean>(false);

  const user = useUserStore((state) => state.user);

  const [logout, { error: mutationError, data: mutationData }] =
    useMutation(MUTATION_LOGOUT);

  useEffect(() => {
    if (mutationData || mutationError) {
      setTimeout(() => (window.location.href = APP_URLS.DASHBOARD), 1000);
    }
  }, [mutationData, mutationError]);

  const handleLogout = () => {
    setLoggingOut(true);
    setMobileMenuOpen(false);
    logout();
  };

  if (!user) {
    return <></>;
  }

  return (
    <header className="bg-white">
      <nav
        className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8"
        aria-label="Global"
      >
        <a href={APP_URLS.DASHBOARD} className="-m-1.5 p-1.5">
          <span className="sr-only">Ahauz</span>
          <LogoDark />
        </a>
        <div className="flex lg:hidden">
          {loggingOut ? (
            <Loading small />
          ) : (
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          )}
        </div>
        <div className="hidden lg:flex lg:gap-x-12">
          {loggingOut ? (
            <Loading small />
          ) : (
            <>
              {navigation.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className="text-base font-medium leading-6 text-brand-copy-primary"
                >
                  {item.name}
                </a>
              ))}
              <button
                className="link text-base font-medium leading-6 text-brand-copy-primary"
                onClick={handleLogout}
              >
                <span>Logout</span>
              </button>
            </>
          )}
        </div>
      </nav>
      <Dialog
        as="div"
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <a href={APP_URLS.DASHBOARD} className="-m-1.5 p-1.5">
              <span className="sr-only">Ahauz</span>
              <LogoDark />
            </a>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="divide-y divide-gray-300">
              <div className="space-y-2 py-6 text-center">
                {navigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className="-mx-3 block rounded-lg px-3 py-2 text-lg font-medium leading-7 text-brand-copy-primary"
                  >
                    {item.name}
                  </a>
                ))}
              </div>
              <div className="py-6 text-center">
                <button
                  className="-mx-3 rounded-lg px-3 py-2.5 text-lg font-medium leading-7 text-brand-copy-primary"
                  onClick={handleLogout}
                >
                  <span>Logout</span>
                </button>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
};

export default Header;
