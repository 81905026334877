import { ALLOWED_FILE_TYPE_EXTENSIONS } from "../consts";

interface UploadInputProps {
  documentType: string;
  remainingSlots: number;
  uploadingDocumentType: string | null;
  deletingId: string | null;
  onFileUpload: (files: FileList, documentType: string) => void;
}

const UploadInput: React.FC<UploadInputProps> = ({
  documentType,
  remainingSlots,
  uploadingDocumentType,
  deletingId,
  onFileUpload,
}) => {
  return (
    <div className="relative mt-4">
      <input
        type="file"
        data-testid={`file-upload-input-${documentType.toLowerCase()}`}
        multiple
        accept={ALLOWED_FILE_TYPE_EXTENSIONS}
        max={remainingSlots}
        onChange={(e) => {
          e.target.files && onFileUpload(e.target.files, documentType);
          (e.target as HTMLInputElement).value = ""; // Clear cache for re-upload / delete
        }}
        className="hidden"
        id={`file-upload-${documentType}`}
        disabled={uploadingDocumentType !== null || deletingId !== null}
      />
      <label
        htmlFor={`file-upload-${documentType}`}
        className={`
          flex items-center justify-center rounded-lg border border-dashed px-4 py-2
          ${
            uploadingDocumentType !== null || deletingId !== null
              ? "cursor-wait bg-gray-100"
              : "cursor-pointer bg-gray-50 hover:bg-gray-100"
          }
        `}
      >
        {uploadingDocumentType === documentType ? (
          <span className="text-sm text-gray-500">Uploading...</span>
        ) : (
          <>
            <svg
              className="mr-2 h-5 w-5 text-gray-500"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 4v16m8-8H4"
              />
            </svg>
            <span className="text-sm font-medium text-gray-600">
              Upload file
            </span>
          </>
        )}
      </label>
    </div>
  );
};

export default UploadInput;
