import {
  STYLE_INPUT,
  STYLE_INPUT_MARGIN_TOP,
} from "components/Form/Input/consts";
import FormInputError from "components/Form/Input/Error";
import FormInputLabel from "components/Form/Input/Label";
import { FormInputProps } from "components/Form/Input/types";
import { ChangeEvent, FocusEvent } from "react";

const MAX_TEXT_INPUT_CHARS = 99;

export default function FormInputText({
  id,
  label,
  error,
  disabled = false,
  type = "text",
  requiredField = true,
  register,
}: FormInputProps) {
  return (
    <div>
      <FormInputLabel id={id} label={label} />
      <div className={STYLE_INPUT_MARGIN_TOP}>
        <input
          id={id}
          type={type}
          required
          className={STYLE_INPUT}
          disabled={disabled}
          {...register(id, {
            required: requiredField,
            onChange: (e: ChangeEvent<HTMLInputElement>) => {
              e.target.value = e.target.value.slice(0, MAX_TEXT_INPUT_CHARS);
            },
            onBlur: (e: FocusEvent<HTMLInputElement>) => {
              e.target.value = e.target.value.trim();
            },
          })}
          autoComplete="off"
        />
        <FormInputError error={error} />
      </div>
    </div>
  );
}
