export const LABELS = {
  firstChargeMortgageValue: "First charge mortgage value",
  termOfFirstChargeLoanYears: "Term of first charge loan (years)",
  termOfFirstChargeLoanMonths: "Term of first charge loan (months)",
  isInterestRateFixedForAnInitialPeriod:
    "Is the first charge interest rate fixed for an initial period?",
  termOfInitialInterestRatePeriodYears:
    "Term of initial fixed interest rate period of first charge mortgage (years)",
  firstChargeInitialInterestRatePercent:
    "First charge initial interest rate (%)",
  firstChargeSvrPercent: "First charge mortgage SVR %",
  propertyPurchasePrice: "Property purchase price",
  equityLoanValue: "Equity loan value",
  numberOfFinancialDependents:
    "How many financial dependents do the applicants have in total?",
  numberOfFinancialDependentsUnder17: "How many under 17?",
};
