import { ResourceType } from "components/Resources/ResourceList";
import { DEFAULT_MAX_FILES } from "./consts";
import { DocumentType, DocumentUploadType } from "./types";

export const documentTypes: DocumentUploadType[] = [
  {
    type: DocumentType.CUSTOMER_DECLARATION,
    label: "Customer Declaration*",
    description:
      "Signed by the customer, this includes permission for a credit check and agreement to our privacy policy.",
    maxFiles: DEFAULT_MAX_FILES,
  },
  {
    type: DocumentType.RESERVATION_FORM,
    label: "Reservation Form",
    description:
      "Copy of the form, confirming the reservation with the house builder.",
    maxFiles: DEFAULT_MAX_FILES,
  },
  {
    type: DocumentType.INCOME_PROOF,
    label: "Income Proof",
    description:
      "Latest three-month payslips if the applicant is employed. Please refer to the Lending Criteria for acceptable proof of income for other types of employment.",
    maxFiles: DEFAULT_MAX_FILES,
  },
  {
    type: DocumentType.EXPENDITURE_DETAILS,
    label: "Expenditure Details",
    description: "Latest three-month bank statements.",
    maxFiles: DEFAULT_MAX_FILES,
  },
  {
    type: DocumentType.PROOF_OF_DEPOSIT,
    label: "Proof of Deposit",
    description: "Evidence of the deposit amount provided by the customer.",
    maxFiles: DEFAULT_MAX_FILES,
  },
  {
    type: DocumentType.INTEREST_ONLY_DECLARATION,
    label: "Interest Only Declaration*",
    description: "Declaration required for all applications.",
    maxFiles: DEFAULT_MAX_FILES,
  },
  {
    type: DocumentType.INTEREST_ONLY_SALE_OF_MAIN_RESIDENCE_CUSTOMER_DECLARATION,
    label: "Interest-Only - Sale of Main Residence Customer Declaration*",
    description:
      "If the sale of the main residence/mortgaged property is the customer's repayment strategy, this document is required.",
    maxFiles: DEFAULT_MAX_FILES,
  },
  {
    type: DocumentType.GIFT_DEPOSIT_DECLARATION,
    label: "Gift Deposit Declaration*",
    description:
      "Required if some of the deposit brought by the borrower is a gift.",
    maxFiles: DEFAULT_MAX_FILES,
  },
  {
    type: DocumentType.OCCUPIERS_CONSENT_FORM,
    label: "Occupier's Consent Form*",
    description:
      "Required if there will be an adult occupier living in the property who is not a party to the mortgage.",
    maxFiles: DEFAULT_MAX_FILES,
  },
  {
    type: DocumentType.FIRST_CHARGE_MORTGAGE_OFFER,
    label: "First Charge Mortgage Offer",
    description: "Copy of the first charge lender's mortgage offer.",
    maxFiles: DEFAULT_MAX_FILES,
  },
];

export const linkDocumentTypes: ResourceType[] = [
  {
    type: "PRODUCT_FEE",
    name: "Product Fee",
    description:
      "Please note that we will only start underwriting the case once the customer has paid this fee. Please use the link:",
    url: "https://pay.gocardless.com/BRT0003HW780NFB",
  },
  {
    type: "DIRECT_DEBIT_MANDATE",
    name: "Direct Debit Mandate",
    description:
      "The Direct Debit will be set up electronically directly with our mortgage servicer - HMS. Please use the link:",
    url: "https://pay.gocardless.com/BRT0003CKHYZCD2",
  },
  {
    type: "ID_KYC_VERIFICATION",
    name: "ID/KYC Verification",
    description:
      "This is done electronically. Please share the link with the customer:",
    url: "https://ahauz.com/customer-verification ",
  },
];
