import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { APP_URLS } from "settings";

const RESOURCE_TYPES = [
  {
    name: "All",
    key: "all",
  },
  {
    name: "Literature",
    key: "literature",
  },
  {
    name: "Applicaton",
    key: "application",
  },
];

const FilterSidebar: React.FC = () => {
  const { resourceType: currentResourceType } = useParams<{
    resourceType: string;
  }>();
  const navigate = useNavigate();

  return (
    <>
      <ul className="flex flex-col space-y-4">
        {RESOURCE_TYPES.map((resourceType) => (
          <li key={resourceType.key}>
            <button
              className={`hover:underline ${
                resourceType.key === currentResourceType ||
                (resourceType.key === "all" && !currentResourceType)
                  ? "font-semibold underline"
                  : ""
              }`}
              onClick={() =>
                resourceType.key === "all"
                  ? navigate(`${APP_URLS.RESOURCES}`)
                  : navigate(`${APP_URLS.RESOURCES}/${resourceType.key}`)
              }
            >
              {resourceType.name}
            </button>
          </li>
        ))}
      </ul>
    </>
  );
};

export default FilterSidebar;
