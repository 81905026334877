const DEFAULT_FORM_DATA = {
  loan: {
    firstChargeMortgageValue: "",
    termOfFirstChargeLoanYears: undefined,
    termOfFirstChargeLoanMonths: undefined,
    isInterestRateFixedForAnInitialPeriod: undefined,
    termOfInitialInterestRatePeriodYears: undefined,
    firstChargeInitialInterestRatePercent: "",
    firstChargeSvrPercent: "",
    propertyPurchasePrice: "",
    equityLoanValue: "",
    numberOfFinancialDependents: undefined,
    numberOfFinancialDependentsUnder17: undefined,
  },
};

export default DEFAULT_FORM_DATA;
