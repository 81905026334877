import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import FormTitle from "components/Form/Title";
import { useState } from "react";

const Sidebar: React.FC<{ title: string; children: React.ReactNode }> = ({
  title,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <div className="mb-6 md:mb-0 md:basis-1/4">
      <div className="relative flex flex-col space-y-4 rounded-lg border border-gray-200 bg-white p-6 md:p-8">
        <div className={`flex justify-between ${isOpen && "border-b pb-4"}`}>
          <FormTitle left title={title} mb={false} />
          <button
            className="block md:hidden"
            onClick={() => setIsOpen(!isOpen)}
          >
            {isOpen ? (
              <ChevronUpIcon className="h-6 w-6" />
            ) : (
              <ChevronDownIcon className="h-6 w-6" />
            )}
          </button>
        </div>
        {isOpen && children}
      </div>
    </div>
  );
};

export default Sidebar;
