import Loading from "components/Loading";
import PageContent from "components/Page/Content";

const MainStateContainer: React.FC<{
  title: string;
  loading?: boolean;
  error?: boolean;
  children?: React.ReactNode;
}> = ({ title, loading = false, error = false, children }) => (
  <PageContent title={title}>
    <div className="flex w-full flex-col md:flex-row md:space-x-8">
      <div className="w-full rounded-lg border border-gray-200 bg-white p-6 text-center md:p-8">
        {loading && (
          <>
            <Loading />
            <p className="mt-4">Loading {title}...</p>
          </>
        )}
        {error && (
          <>
            <p>Error loading {title}.</p>
            <p className="mt-2">
              Please try again later or contact support if the problem persists.
            </p>
          </>
        )}
        {children && children}
      </div>
    </div>
  </PageContent>
);

export default MainStateContainer;
