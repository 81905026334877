import React from "react";
import { Link } from "react-router-dom";

import FormTitle from "components/Form/Title";
import { APP_URLS } from "settings";
import { DipType } from "ts/interfaces/common/dip";

interface DecisionProps {
  dip?: DipType | null;
}

const DipDecisionFail: React.FC<DecisionProps> = ({ dip }) => {
  return (
    <div className="space-y-4">
      <FormTitle
        left
        border
        title="The Decision in Principle has been referred"
        mb={false}
      />

      <div className="space-y-2">
        <p>
          The Decision in Principle needs to be reviewed, we'll be in touch
          shortly to discuss.
        </p>

        {dip?.case?.reference && (
          <>
            <p>
              You can track your Case ({dip.case.reference}) attached to this
              Decision in Principle{" "}
              <Link
                to={`${APP_URLS.DASHBOARD}cases/${dip.case.uuid}`}
                className="link underline"
              >
                here
              </Link>
              .
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default DipDecisionFail;
