import React from "react";
import { Link, useNavigate } from "react-router-dom";

import FormTitle from "components/Form/Title";
import { APP_URLS } from "settings";
import useApplicationStore from "store/Application";
import { DipType } from "ts/interfaces/common/dip";

interface DecisionProps {
  dip: DipType;
}

const DipDecisionPass: React.FC<DecisionProps> = ({ dip }) => {
  let navigate = useNavigate();

  const { setAppllicationCase, resetApplication } = useApplicationStore();

  const startNewApplication = () => {
    resetApplication();

    if (dip?.case) {
      setAppllicationCase({
        reference: dip.case.reference,
        uuid: dip.case.uuid,
      });
    }
    navigate(APP_URLS.APPLICATION_FORM);
  };

  return (
    <div className="space-y-4">
      <FormTitle
        left
        border
        title="The Decision in Principle has been generated"
        mb={false}
      />

      <div className="space-y-2">
        <p>
          View and download the Decision in Principle:{" "}
          <a download href={dip?.downloadUrl} className="link underline">
            Download
          </a>
        </p>
        <p>
          To continue with the application process, please submit an application
          form. You can find it{" "}
          <button onClick={startNewApplication} className="link underline">
            here
          </button>
          .
        </p>

        {dip?.case?.reference && (
          <>
            <p>
              You can track your Case ({dip.case.reference}) attached to this
              Decision in Principle{" "}
              <Link
                to={`${APP_URLS.DASHBOARD}cases/${dip.case.uuid}`}
                className="link underline"
              >
                here
              </Link>
              .
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default DipDecisionPass;
