import { useLazyQuery, useMutation } from "@apollo/client";
import * as Sentry from "@sentry/react";
import {
  MUTATION_DELETE_APPLICATION_DOCUMENT,
  MUTATION_UPLOAD_APPLICATION_DOCUMENTS,
} from "api/mutations/application";
import { QUERY_APPLICATION_DOCUMENTS } from "api/queries/application";
import FormTitle from "components/Form/Title";
import PageContent from "components/Page/Content";
import Sidebar from "components/Page/Sidebar";
import ResourceList from "components/Resources/ResourceList";
import { isCaseComplete } from "pages/ApplicationForm/utils";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { APP_URLS } from "settings";
import MainStateContainer from "../../components/Page/MainStateContainer";
import AppDocsSidebar from "./components/AppDocsSidebar";
import FileError from "./components/FileError";
import UploadedFileList from "./components/UploadedFileList";
import UploadInput from "./components/UploadInput";
import {
  ALLOWED_FILE_TYPES,
  EMAIL_DOCUMENT_TO_US_COPY,
  MAX_FILE_SIZE,
  USER_ERROR_PREFIX,
} from "./consts";
import { documentTypes, linkDocumentTypes } from "./documentTypes";
import {
  ApplicationDocument,
  ApplicationType,
  DocumentUploadStateType,
} from "./types";

const ApplicationDocuments: React.FC = () => {
  const navigate = useNavigate();

  const { applicationId: urlApplicationId } = useParams<{
    applicationId: string;
  }>();

  const [uploadApplicationDocuments] = useMutation(
    MUTATION_UPLOAD_APPLICATION_DOCUMENTS,
  );
  const [deleteDocument] = useMutation(MUTATION_DELETE_APPLICATION_DOCUMENT);

  const [application, setApplication] = useState<ApplicationType | null>(null);
  const [documentState, setDocumentState] = useState<DocumentUploadStateType>({
    uploading: null,
    uploadError: {},
    deleteError: {},
    deletingId: null,
    documentsError: null,
  });

  const [
    getApplicationDocuments,
    {
      data: applicationDocuments,
      loading: applicationDocumentsLoading,
      error: applicationDocumentsError,
    },
  ] = useLazyQuery(QUERY_APPLICATION_DOCUMENTS, { fetchPolicy: "no-cache" });

  useEffect(() => {
    if (applicationDocuments?.error || applicationDocumentsError) {
      setDocumentState((prev) => ({
        ...prev,
        documentsError:
          applicationDocuments?.error || applicationDocumentsError,
      }));
    }
  }, [applicationDocuments?.error, applicationDocumentsError]);

  useEffect(() => {
    if (urlApplicationId && !application) {
      getApplicationDocuments({
        variables: {
          applicationId: urlApplicationId,
        },
      });
    }
  }, [urlApplicationId, application, getApplicationDocuments]);

  useEffect(() => {
    if (!application && applicationDocuments?.application) {
      // No longer allow editing of documents for completed cases
      if (isCaseComplete(applicationDocuments.application.case?.status)) {
        return navigate(
          `${APP_URLS.CASE_MANAGEMENT}${applicationDocuments.application.case?.uuid}/`,
        );
      }
      setApplication(applicationDocuments.application);
    }
  }, [application, applicationDocuments, setApplication, navigate]);

  const getExistingFiles = (documentType: string) => {
    if (!applicationDocuments?.application?.documents) {
      return [];
    }
    return applicationDocuments.application.documents.filter(
      (doc: ApplicationDocument) => doc.documentType === documentType,
    );
  };

  const handleError = (error: any, errorMessage: string) => {
    Sentry.captureException(error);
    if (error?.message && error.message.includes(USER_ERROR_PREFIX)) {
      return error.message.replace(USER_ERROR_PREFIX, "");
    }
    return errorMessage;
  };

  const updateExistingFilesState = async () => {
    if (!application?.uuid) return;

    return getApplicationDocuments({
      variables: {
        applicationId: application.uuid,
      },
    });
  };

  const resetDocumentStateErrors = (
    documentType: string,
    isUploading: boolean = false,
  ) => {
    setDocumentState((prev) => ({
      ...prev,
      uploading: isUploading ? documentType : prev.uploading,
      uploadError: {
        ...prev.uploadError,
        [documentType]: null,
      },
      deleteError: {
        ...prev.deleteError,
        [documentType]: null,
      },
    }));
  };

  const handleDelete = async (documentId: string, documentType: string) => {
    resetDocumentStateErrors(documentType, false);

    try {
      await deleteDocument({
        variables: { id: documentId },
      });
      await updateExistingFilesState();
    } catch (error) {
      setDocumentState((prev) => ({
        ...prev,
        deleteError: {
          ...prev.deleteError,
          [documentType]: handleError(
            error,
            "Failed to delete document. Please try again or contact support if the problem persists.",
          ),
        },
      }));
    } finally {
      setDocumentState((prev) => ({ ...prev, deletingId: null }));
    }
  };

  const handleFileUpload = async (files: FileList, documentType: string) => {
    resetDocumentStateErrors(documentType, true);

    try {
      // Check file sizes
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (file.size > MAX_FILE_SIZE) {
          throw new Error(
            `${USER_ERROR_PREFIX}File "${file.name}" exceeds maximum size of 10MB. ${EMAIL_DOCUMENT_TO_US_COPY}`,
          );
        }
      }

      // Check file types
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (!ALLOWED_FILE_TYPES.includes(file.type)) {
          throw new Error(
            `${USER_ERROR_PREFIX}File "${file.name}" is not an allowed file type. Please upload PDF, image, Excel or Word documents only. ${EMAIL_DOCUMENT_TO_US_COPY}`,
          );
        }
      }

      const uploadData = Array.from(files).map((file) => ({
        file,
        documentType,
      }));

      await uploadApplicationDocuments({
        variables: {
          applicationId: application?.uuid,
          data: uploadData,
        },
      });

      await updateExistingFilesState();
    } catch (error) {
      setDocumentState((prev) => ({
        ...prev,
        uploadError: {
          ...prev.uploadError,
          [documentType]: handleError(
            error,
            `Failed to upload documents. ${EMAIL_DOCUMENT_TO_US_COPY}`,
          ),
        },
      }));
    } finally {
      setDocumentState((prev) => ({ ...prev, uploading: null }));
    }
  };

  if (!application && applicationDocumentsLoading) {
    return <MainStateContainer title="Application Documents" loading />;
  }

  if (documentState.documentsError) {
    return <MainStateContainer title="Application Documents" error />;
  }

  return (
    <PageContent title="Application Documents">
      <div className="flex w-full flex-col md:flex-row md:space-x-8">
        <Sidebar title="Application">
          <AppDocsSidebar application={application} />
        </Sidebar>

        <div className="md:basis-3/4">
          <div className="rounded-lg border border-gray-200 bg-white p-6 md:p-8">
            <div className="flex flex-col space-y-4">
              <FormTitle left border title="Documents" mb={false} />

              <p>
                Please upload the required documents below. Documents marked
                with an asterisk (*) require Ahauz-specific forms. You can
                download these, here:{" "}
                <a
                  href={`${APP_URLS.RESOURCES}/application`}
                  target="_blank"
                  rel="noreferrer"
                  className="link underline"
                >
                  Application Resources
                </a>
                .
              </p>

              <div className="space-y-6 pt-6">
                <ResourceList showLink resources={linkDocumentTypes} />

                {documentTypes.map((documentType) => {
                  const existingFiles = getExistingFiles(documentType.type);
                  const remainingSlots =
                    documentType.maxFiles - existingFiles.length;

                  return (
                    <div
                      key={documentType.type}
                      className="border-b bg-white pb-6 last:border-b-0 last:pb-0"
                    >
                      <div className="mt-4 flex items-start justify-between first:mt-0">
                        <div>
                          <h2 className="text-lg font-medium">
                            {documentType.label}
                          </h2>
                          <p className="mt-1 text-sm text-gray-600">
                            {documentType.description}
                          </p>
                        </div>
                      </div>

                      {existingFiles.length > 0 && (
                        <UploadedFileList
                          files={existingFiles}
                          deletingId={documentState.deletingId}
                          onDelete={(documentId) =>
                            handleDelete(documentId, documentType.type)
                          }
                        />
                      )}

                      {(documentState.uploadError[documentType.type] ||
                        documentState.deleteError[documentType.type]) && (
                        <FileError
                          error={
                            documentState.uploadError[documentType.type] ||
                            documentState.deleteError[documentType.type]
                          }
                        />
                      )}

                      {remainingSlots > 0 && (
                        <UploadInput
                          documentType={documentType.type}
                          remainingSlots={remainingSlots}
                          uploadingDocumentType={documentState.uploading}
                          deletingId={documentState.deletingId}
                          onFileUpload={handleFileUpload}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageContent>
  );
};

export default ApplicationDocuments;
