import { XCircleIcon } from "@heroicons/react/20/solid";

interface FileErrorMessageProps {
  error: string | null;
}

const FileError: React.FC<FileErrorMessageProps> = ({ error }) => {
  return (
    <div className="-mb-2 mt-4 rounded-lg bg-red-50 p-2">
      <div className="flex">
        <div className="flex-shrink-0">
          <XCircleIcon className="h-5 w-5 text-red-800" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <p className="text-sm text-red-800">{error}</p>
        </div>
      </div>
    </div>
  );
};

export default FileError;
