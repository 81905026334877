import CTAButton from "components/CTA/Button";
import { APP_URLS } from "settings";
import { Illustration } from "ts/interfaces/common/esis";
import { renderDate } from "utils/helpers";

// Define the props interface
interface IllustrationsProps {
  caseID: string;
  illustrations: Illustration[]; // Array of illustration items
}

const Illustrations: React.FC<IllustrationsProps> = ({
  caseID,
  illustrations,
}) => {
  const routeToCreateIllustration = () => {
    window.location.href = `${APP_URLS.CREATE_ILLUSTRATION}?case=${caseID}`;
  };

  return (
    <>
      <div className="flex items-end justify-between">
        <h3 className="text-lg font-semibold">Illustrations</h3>
        <CTAButton
          label="Create Illustration"
          onClick={routeToCreateIllustration}
        />
      </div>
      <div className="mt-4 flex flex-col">
        <div className="overflow-hidden rounded-lg shadow ring-1 ring-black ring-opacity-5">
          <table className="min-w-full divide-y divide-gray-300">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                >
                  Reference
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Date created
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  <span className="sr-only">Action</span>
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {illustrations.length ? (
                illustrations.map((ill, i) => (
                  <tr key={i}>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                      {ill.reference}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {renderDate(ill.createdOn)}
                    </td>
                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                      <a
                        download
                        href={ill?.downloadUrl}
                        className="link underline"
                      >
                        Download
                      </a>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={4}
                    className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
                  >
                    -
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Illustrations;
