export const DEFAULT_MAX_FILES = 20;
export const USER_ERROR_PREFIX = "[USER_ERROR]";
export const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB in bytes
export const ALLOWED_FILE_TYPES = [
  "application/pdf",
  "image/jpeg",
  "image/png",
  "image/gif",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
];
export const ALLOWED_FILE_TYPE_EXTENSIONS =
  ".pdf,.jpg,.jpeg,.png,.gif,.xls,.xlsx,.doc,.docx";
export const EMAIL_DOCUMENT_TO_US_COPY =
  "If you are unable to upload a document, please email it to intermediaries@ahauz.com";
