import { Link } from "react-router-dom";
import { APP_URLS } from "settings";
import { ApplicationType } from "../types";

interface SidebarProps {
  application: ApplicationType | null;
}

const AppDocsSidebar: React.FC<SidebarProps> = ({ application }) => {
  if (application) {
    return (
      <div>
        {application.reference && application.modifiedOn && (
          <>
            <span className="mb-1 block">
              <span className="font-semibold">Reference:</span>
              <br />
              {application.reference}
            </span>
            <span className="mb-1 block">
              <span className="font-semibold">Date submitted:</span>
              <br />
              {new Date(application.applicationDate).toLocaleDateString(
                "en-GB",
              )}
            </span>
          </>
        )}
        {application.case.reference && (
          <span className="block">
            <span className="font-semibold">Case:</span>
            <br />
            <Link
              to={`${APP_URLS.DASHBOARD}cases/${application.case.uuid}`}
              className="underline"
            >
              {application.case.reference}
            </Link>
          </span>
        )}
      </div>
    );
  }

  return <></>;
};

export default AppDocsSidebar;
