import { gql } from "@apollo/client";

export const QUERY_DIP = gql`
  query Dip($uuid: String!) {
    dip(uuid: $uuid) {
      uuid
      reference
      createdOn
      downloadUrl
      decision
      success
      case {
        uuid
        status
        reference
      }
    }
  }
`;
