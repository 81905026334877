const DEFAULT_FORM_DATA = {
  loan: {
    purposeOfMortgage: "",
    depositAmount: "",
    firstChargeMortgageValue: "",
    valueOfFeeAddedToFirstChargeMortgage: "",
    equityLoanAmountRequired: "",
    propertyPurchasePrice: "",
    isFirstChargeARepaymentMortgage: undefined,
    firstChargeLender: "",
    termOfFirstChargeLoanYears: undefined,
    termOfFirstChargeLoanMonths: undefined,
    isInterestRateFixedForAnInitialPeriod: undefined,
    termOfInitialInterestRatePeriodYears: undefined,
    firstChargeInitialInterestRatePercent: "",
    firstChargeSvrPercent: "",
    equityLoanRepaymentPlan: "",
    otherRepaymentPlan: "",
    sourceOfDeposit: "",
    otherSourceOfDeposit: "",
    isAllDepositByOwnResource: undefined,
    otherDepositAmount: "",
    otherDepositSource: "",
    isAllOrPartDepositAGift: undefined,
    isGiftFromCurrentOwner: undefined,
    willGifterLiveInPropertyAfterPurchase: undefined,
    willGifterTakeInterestInPropertyForGift: undefined,
    amountOfGift: "",
    gifterDetail: "",
  },
};

export default DEFAULT_FORM_DATA;
