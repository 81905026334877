import { useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Dips from "./components/Dips";
import Illustrations from "./components/Illustrations";

import { MUTATION_DELETE_CASE } from "api/mutations/cases";
import { QUERY_CASE_DETAIL } from "api/queries/cases";
import ApplicationList from "components/Application/List";
import CTAButton from "components/CTA/Button";
import GenericError from "components/GenericError";
import Loading from "components/Loading";
import ActionModal from "components/Modal/Action";
import PageContent from "components/Page/Content";
import { APP_URLS } from "settings";
import { renderDate } from "utils/helpers";
import Container from "../../components/Page/Container";

// Define types for the query and mutation response data
interface CaseDetailData {
  case: {
    uuid: string;
    reference: string;
    statusDisplay: string;
    status: string;
    applicantNames: string;
    createdOn: string;
    dips: any[];
    illustrations: any[];
    applications: any[];
  };
}

interface DeleteCaseResponse {
  caseDelete: {
    ok: boolean;
  };
}

interface DeleteCaseVariables {
  case: string;
}

const deleteDescription = `Are you sure you want to delete this case?
                          Deleting a case will delete all Illustrations and Applications
                          associated with it.`;

const CaseDetail: React.FC = () => {
  const { caseID } = useParams<{
    caseID: string;
  }>();

  const navigate = useNavigate();

  const { loading, error, data } = useQuery<CaseDetailData>(QUERY_CASE_DETAIL, {
    variables: {
      uuid: caseID,
    },
    fetchPolicy: "no-cache",
  });

  const [deleteCase, { data: mutationData, error: deleteCaseError }] =
    useMutation<DeleteCaseResponse, DeleteCaseVariables>(MUTATION_DELETE_CASE);

  const [deleteModal, setDeleteModal] = useState(false);

  useEffect(() => {
    if (mutationData?.caseDelete.ok) {
      navigate(APP_URLS.CASE_MANAGEMENT);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutationData]);

  const detail = data?.case;

  const handleDeleteCase = async () => {
    setDeleteModal(false);
    deleteCase({
      variables: {
        case: caseID!,
      },
    });
  };

  const renderDeleteModal = () =>
    deleteModal && (
      <ActionModal
        show={deleteModal}
        title="Delete case"
        description={deleteDescription}
        proceedText="Delete"
        onCancel={() => setDeleteModal(false)}
        onProceed={handleDeleteCase}
      />
    );

  const renderCaseInfo = () => (
    <div className="mb-6 md:mb-0 md:basis-1/4">
      <div className="flex flex-col space-y-4 rounded-lg border border-gray-200 bg-white p-6 md:p-8">
        {detail?.statusDisplay && (
          <span>
            <span className="font-semibold">Status:</span>
            <br />
            <span>{detail?.statusDisplay}</span>
          </span>
        )}
        <span>
          <span className="font-semibold">Applicant(s):</span>
          <br />
          <span>{detail?.applicantNames}</span>
        </span>
        <span>
          <span className="font-semibold">Reference(s):</span>
          <br />
          <span>{detail?.reference}</span>
        </span>
        <span>
          <span className="font-semibold">Created(s):</span>
          <br />
          <span>{renderDate(detail?.createdOn)}</span>
        </span>
      </div>

      {detail && (
        <div className="mt-4">
          <CTAButton label="Delete Case" onClick={() => setDeleteModal(true)} />
        </div>
      )}
    </div>
  );

  const renderContent = () => {
    if (loading) {
      return (
        <Container>
          <Loading />
        </Container>
      );
    }

    if (
      error ||
      deleteCaseError ||
      (mutationData?.caseDelete && !mutationData.caseDelete.ok)
    ) {
      return (
        <Container>
          <GenericError error={error} message="Error deleting Case" />
        </Container>
      );
    }

    if (data && !data.case) {
      return (
        <Container>
          <GenericError error={error} message="Case not found" />
        </Container>
      );
    }

    return (
      <>
        {detail?.dips && (
          <div>
            <Dips dipCase={detail} dips={detail.dips} />
          </div>
        )}
        {detail?.illustrations && (
          <div>
            <Illustrations
              caseID={caseID!}
              illustrations={detail.illustrations}
            />
          </div>
        )}
        {detail?.applications && (
          <div>
            <ApplicationList
              showSubmittedDate
              applicationCase={detail}
              applications={detail.applications}
            />
          </div>
        )}
      </>
    );
  };

  return (
    <>
      {renderDeleteModal()}
      <div className="md:basis-3/4">
        <PageContent title={`Case ${detail?.reference || ""}`}>
          <div className="grid md:grid-cols-4 md:gap-8">
            {renderCaseInfo()}
            <div className="space-y-8 px-0 py-8 md:col-span-3">
              {renderContent()}
            </div>
          </div>
        </PageContent>
      </div>
    </>
  );
};

export default CaseDetail;
