import { CheckCircleIcon } from "@heroicons/react/24/outline";
import React from "react";
import { Link } from "react-router-dom";
import { APP_URLS } from "settings";
import useApplicationStore from "store/Application";
import { APPLICATION_COMPLETE_KEY } from "utils/consts";
import { FormSchemaStatus } from "../formSchema";
import { STEPS, StepType, TOTAL_STEPS } from "../formSteps";

interface SidebarProps {
  currentStep: StepType;
  onStepClick: (
    stepNumber: number,
    stepKey: string,
    shouldValidate: boolean,
  ) => void;
}

const ApplicationSidebar: React.FC<SidebarProps> = ({
  currentStep,
  onStepClick,
}) => {
  const { applicationDetail, formStatus, applicationCase } =
    useApplicationStore();

  const renderApplicationInfo = () => {
    return (
      <>
        {(applicationDetail?.reference || applicationCase?.reference) && (
          <div className="mb-6 border-b pb-4 md:-mt-4">
            {applicationDetail?.reference && applicationDetail?.modifiedOn && (
              <>
                <span className="mb-1 block">
                  <span className="font-semibold">Reference:</span>
                  <br />
                  {applicationDetail?.reference}
                </span>
                <span className="mb-1 block">
                  <span className="font-semibold">Updated on:</span>
                  <br />
                  {new Date(applicationDetail?.modifiedOn).toLocaleDateString(
                    "en-GB",
                  )}
                </span>
              </>
            )}
            {applicationCase?.reference && (
              <span className="block">
                <span className="font-semibold">Case:</span>
                <br />
                <Link
                  to={`${APP_URLS.DASHBOARD}cases/${applicationCase.uuid}`}
                  className="underline"
                  target="_blank"
                >
                  {applicationCase.reference}
                </Link>
              </span>
            )}
          </div>
        )}
      </>
    );
  };

  const renderNavItemWithProgress = (step: StepType) => {
    const currentStepStatus =
      formStatus[currentStep.key as keyof FormSchemaStatus];
    const listStepStatus = formStatus[step.key as keyof FormSchemaStatus];
    const shouldValidate =
      currentStepStatus === APPLICATION_COMPLETE_KEY &&
      currentStep.id < TOTAL_STEPS;

    return (
      <li key={step.key}>
        <button
          className={`flex w-full justify-between text-left ${
            currentStep.id === step.id
              ? "font-semibold underline"
              : "hover:underline"
          }`}
          onClick={() => onStepClick(step.id, step.key, shouldValidate)}
        >
          <span>{step.name}</span>
          <span>
            {listStepStatus === APPLICATION_COMPLETE_KEY && (
              <CheckCircleIcon className="text-secondary h-6 w-6 self-center text-green-400" />
            )}
          </span>
        </button>
      </li>
    );
  };

  return (
    <>
      {renderApplicationInfo()}
      <ul className="flex flex-col space-y-4">
        {Object.entries(STEPS).map(([key, step]) =>
          renderNavItemWithProgress(step),
        )}
      </ul>
    </>
  );
};

export default ApplicationSidebar;
