import CTAButton from "components/CTA/Button";
import { useNavigate } from "react-router-dom";
import { APP_URLS } from "settings";
import useDipStore from "store/DecisionInPrinciple";
import { renderDate } from "utils/helpers";

// Define the types for the props
interface DipCase {
  reference: string;
  uuid: string;
}

interface DipsProps {
  dips: any[]; // Replace 'any[]' with the actual type if you have it for dips
  dipCase: DipCase;
}

const Dips: React.FC<DipsProps> = ({ dipCase, dips }) => {
  const navigate = useNavigate();

  const { setDipCase } = useDipStore();

  const startNewDip = () => {
    setDipCase({
      reference: dipCase.reference,
      uuid: dipCase.uuid,
    });
    navigate(APP_URLS.DECISION_IN_PRINCIPLE);
  };

  return (
    <>
      <div className="flex items-end justify-between">
        <h3 className="text-lg font-semibold">Decision in Principle</h3>
        <CTAButton label="Create DIP" onClick={startNewDip} />
      </div>
      <div className="mt-4 flex flex-col">
        <div className="overflow-hidden rounded-lg shadow ring-1 ring-black ring-opacity-5">
          <table className="w-full table-auto divide-y divide-gray-300">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                >
                  Reference
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Date created
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Decision
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  <span className="sr-only">Action</span>
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {dips.length ? (
                dips.map((dip, i) => (
                  <tr key={i}>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                      {dip.reference}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {renderDate(dip.createdOn)}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {dip.decisionDisplay}
                    </td>
                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                      {dip.success && (
                        <a
                          download
                          href={dip?.downloadUrl}
                          className="link underline"
                        >
                          Download
                        </a>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={4}
                    className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
                  >
                    -
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Dips;
