import { baseApplicantsValidation } from "components/Application/Form/ApplicantDetails/validation";
import { PHONE_NUMBER_REGEX, VALIDATION_PHONE_NUMBER } from "utils/validation";
import * as Yup from "yup";

const validation: Yup.ObjectSchema<any> = Yup.object().shape({
  applicants: Yup.array().of(
    Yup.object({
      details: baseApplicantsValidation.shape({
        phoneNumber: Yup.string().nullable().matches(PHONE_NUMBER_REGEX, {
          message: VALIDATION_PHONE_NUMBER,
          excludeEmptyString: true,
        }),

        email: Yup.string().nullable().email("Email is invalid"),
      }),
    }),
  ),
});

export default validation;
