import { gql } from "@apollo/client";

export const QUERY_RESOURCES = gql`
  query GetResources($resourceType: String) {
    resources(resourceType: $resourceType) {
      resourceType
      name
      description
      url
    }
  }
`;
