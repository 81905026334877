import { setDataForApi } from "utils/api";

export const parseApplicantDetail = (applicant: any) => {
  if (!applicant) {
    return applicant;
  }

  if (applicant?.nationality === "BRITISH") {
    applicant.hasPermanentUkResideRight = null;
    applicant.visaType = null;
    applicant.hasRefugeeAsylumStatus = null;
  }

  const { currentAddress, previousAddresses } = applicant;

  if (currentAddress) {
    setDataForApi(currentAddress);
  }

  if (previousAddresses && Array.isArray(previousAddresses)) {
    previousAddresses.forEach((address) => setDataForApi(address));
  }

  setDataForApi(applicant);

  return applicant;
};
