import React, { useEffect } from "react";

import FormInputCurrency from "components/Form/Input/Currency";
import FormInputNumber from "components/Form/Input/Number";
import FormInputPercent from "components/Form/Input/Percent";
import FormInputRadio from "components/Form/Input/Radio";
import FormTitle from "components/Form/Title";
import FormErrors from "pages/ApplicationForm/components/FormErrors";
import { StepProps } from "pages/DecisionInPrinciple/types";
import { calcEquityLoanValue } from "utils/calcs";
import { checkTruthyValue } from "utils/validation";
import { LABELS } from "./labels";

const STEP_KEY = "loan";

const LoanForm: React.FC<StepProps> = ({
  watch,
  register,
  errors,
  setValue,
}) => {
  const loanDetail = watch(STEP_KEY);
  const propertyValue = loanDetail?.propertyPurchasePrice;

  useEffect(() => {
    const _equityLoanValue = calcEquityLoanValue(propertyValue);
    setValue(`${STEP_KEY}.equityLoanValue`, _equityLoanValue);
  }, [propertyValue, setValue]);

  return (
    <div className="flex flex-col space-y-4">
      <FormTitle left border title="Loan" mb={false} />

      <FormErrors formErrors={errors?.[STEP_KEY]} />

      <FormInputCurrency
        id={`${STEP_KEY}.firstChargeMortgageValue`}
        label={LABELS.firstChargeMortgageValue}
        register={register}
        requiredField={false}
        error={errors?.[STEP_KEY]?.firstChargeMortgageValue?.message}
        setValue={setValue}
      />

      <FormInputNumber
        id={`${STEP_KEY}.termOfFirstChargeLoanYears`}
        label={LABELS.termOfFirstChargeLoanYears}
        register={register}
        requiredField={false}
        error={errors?.[STEP_KEY]?.termOfFirstChargeLoanYears?.message}
      />

      <FormInputNumber
        id={`${STEP_KEY}.termOfFirstChargeLoanMonths`}
        label={LABELS.termOfFirstChargeLoanMonths}
        register={register}
        requiredField={false}
        error={errors?.[STEP_KEY]?.termOfFirstChargeLoanMonths?.message}
      />

      <FormInputRadio
        id={`${STEP_KEY}.isInterestRateFixedForAnInitialPeriod`}
        label={LABELS.isInterestRateFixedForAnInitialPeriod}
        register={register}
        requiredField={false}
        initValue={loanDetail?.isInterestRateFixedForAnInitialPeriod}
        error={
          errors?.[STEP_KEY]?.isInterestRateFixedForAnInitialPeriod?.message
        }
      />

      {checkTruthyValue(loanDetail?.isInterestRateFixedForAnInitialPeriod) && (
        <FormInputNumber
          id={`${STEP_KEY}.termOfInitialInterestRatePeriodYears`}
          label={LABELS.termOfInitialInterestRatePeriodYears}
          register={register}
          requiredField={false}
          error={
            errors?.[STEP_KEY]?.termOfInitialInterestRatePeriodYears?.message
          }
        />
      )}

      <FormInputPercent
        id={`${STEP_KEY}.firstChargeInitialInterestRatePercent`}
        label={LABELS.firstChargeInitialInterestRatePercent}
        register={register}
        requiredField={false}
        error={
          errors?.[STEP_KEY]?.firstChargeInitialInterestRatePercent?.message
        }
      />

      <FormInputPercent
        id={`${STEP_KEY}.firstChargeSvrPercent`}
        label={LABELS.firstChargeSvrPercent}
        register={register}
        requiredField={false}
        error={errors?.[STEP_KEY]?.firstChargeSvrPercent?.message}
      />

      <FormInputCurrency
        id={`${STEP_KEY}.propertyPurchasePrice`}
        label={LABELS.propertyPurchasePrice}
        register={register}
        requiredField={false}
        error={errors?.[STEP_KEY]?.propertyPurchasePrice?.message}
        setValue={setValue}
      />

      <FormInputCurrency
        id={`${STEP_KEY}.equityLoanValue`}
        label={LABELS.equityLoanValue}
        disabled
        register={register}
        setValue={setValue}
        error={errors?.[STEP_KEY]?.equityLoanValue?.message}
        helperText="This value will be automatically calculated as 15% of the Property Purchase Price"
      />

      <FormInputNumber
        id={`${STEP_KEY}.numberOfFinancialDependents`}
        label={LABELS.numberOfFinancialDependents}
        register={register}
        error={errors?.[STEP_KEY]?.numberOfFinancialDependents?.message}
      />

      <FormInputNumber
        id={`${STEP_KEY}.numberOfFinancialDependentsUnder17`}
        label={LABELS.numberOfFinancialDependentsUnder17}
        register={register}
        error={errors?.[STEP_KEY]?.numberOfFinancialDependentsUnder17?.message}
      />
    </div>
  );
};

export default LoanForm;
