import { Link, useNavigate } from "react-router-dom";

import CTAButton from "components/CTA/Button";
import { applicationStepOne } from "pages/ApplicationForm/formSteps";
import {
  canContinueApplication,
  isCaseComplete,
} from "pages/ApplicationForm/utils";
import { getApplicationDocumentsUrl } from "routes/links";
import { APP_URLS } from "settings";
import useApplicationStore from "store/Application";
import { renderDate } from "utils/helpers";

interface Application {
  downloadUrl?: string;
  case?: {
    reference?: string;
    uuid?: string;
    status?: string;
  };
  reference: string;
  createdOn: string;
  broker?: {
    applicationDate?: string;
  };
  statusDisplay: string;
  applicantNames?: string;
  status: string;
  uuid: string;
}

interface ApplicationListProps {
  applications: Application[];
  applicationCase?: { reference: string; uuid: string; status: string } | null;
  title?: string;
  showCase?: boolean;
  showSubmittedDate?: boolean;
  showApplicants?: boolean;
}

const ApplicationList: React.FC<ApplicationListProps> = ({
  applications,
  applicationCase = null,
  title = "Applications",
  showCase = false,
  showSubmittedDate = false,
  showApplicants = false,
}) => {
  const navigate = useNavigate();

  const { setAppllicationCase, resetApplication } = useApplicationStore();

  const navigateToCase = (caseID: string) =>
    navigate(`${APP_URLS.CASE_MANAGEMENT}${caseID}/`);

  const startNewApplication = () => {
    resetApplication();

    if (applicationCase) {
      setAppllicationCase({
        reference: applicationCase.reference,
        uuid: applicationCase.uuid,
      });
    }
    navigate(applicationStepOne());
  };

  const setApplicationToContinue = (applicationID: string) => {
    navigate(applicationStepOne(applicationID));
  };

  const renderApplicationActions = (app: Application) => {
    if (isCaseComplete(applicationCase?.status)) {
      return <></>;
    }

    if (canContinueApplication(app.status)) {
      return (
        <button
          onClick={() => setApplicationToContinue(app.uuid)}
          className="text-primary link font-medium underline"
        >
          Continue
        </button>
      );
    }

    return (
      <>
        <a download href={app?.downloadUrl} className="link underline">
          Download submission
        </a>

        <span className="px-2 text-gray-300">|</span>

        <Link
          to={getApplicationDocumentsUrl(app.uuid)}
          className="link underline"
        >
          Supporting documents
        </Link>
      </>
    );
  };

  return (
    <>
      <div className="flex items-end justify-between">
        <h3 className="text-lg font-semibold">{title}</h3>
        <CTAButton label="Create Application" onClick={startNewApplication} />
      </div>
      <div className="mt-4 flex flex-col">
        <div className="overflow-hidden rounded-lg shadow ring-1 ring-black ring-opacity-5">
          <table className="min-w-full divide-y divide-gray-300">
            <thead className="bg-gray-50">
              <tr>
                {showCase && (
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  >
                    Case
                  </th>
                )}
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                >
                  Reference
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Date started
                </th>
                {showSubmittedDate && (
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Date submitted
                  </th>
                )}
                {showApplicants && (
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Applicant name(s)
                  </th>
                )}
                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                  <span className="sr-only">Action</span>
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {applications.length ? (
                applications.map((app, i) => (
                  <tr key={i}>
                    {showCase && (
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {app?.case?.reference && app?.case?.uuid ? (
                          <button
                            onClick={() =>
                              app?.case?.uuid && navigateToCase(app.case.uuid)
                            }
                            className="text-primary font-medium underline"
                          >
                            {app.case.reference}
                          </button>
                        ) : (
                          "-"
                        )}
                      </td>
                    )}
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                      {app.reference}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {renderDate(app.createdOn)}
                    </td>
                    {showSubmittedDate && (
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {renderDate(app.broker?.applicationDate)}
                      </td>
                    )}
                    {showApplicants && (
                      <td className="whitespace-normal px-3 py-4 text-sm text-gray-500">
                        {app.applicantNames}
                      </td>
                    )}
                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                      {renderApplicationActions(app)}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={5}
                    className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
                  >
                    -
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default ApplicationList;
