import { useQuery } from "@apollo/client";
import { QUERY_RESOURCES } from "api/queries/resources";
import FormTitle from "components/Form/Title";
import Loading from "components/Loading";
import PageContent from "components/Page/Content";
import MainStateContainer from "components/Page/MainStateContainer";
import Sidebar from "components/Page/Sidebar";
import ResourceList from "components/Resources/ResourceList";
import { useParams } from "react-router-dom";
import FilterSidebar from "./components/FilterSidebar";

const Resources = () => {
  const { resourceType } = useParams<{ resourceType: string }>();

  const { loading, error, data } = useQuery(QUERY_RESOURCES, {
    variables: { resourceType: resourceType },
  });

  const resourceTitle = resourceType
    ? `${resourceType} Resources`
    : "All Resources";

  if (error) {
    return <MainStateContainer title="Resources" error />;
  }

  return (
    <PageContent title="Resources">
      <div className="flex w-full flex-col md:flex-row md:space-x-8">
        <Sidebar title="Resource types">
          <FilterSidebar />
        </Sidebar>

        <div className="md:basis-3/4">
          <div className="rounded-lg border border-gray-200 bg-white p-6 md:p-8">
            <div className="flex flex-col space-y-4">
              <div className="capitalize">
                <FormTitle left border title={resourceTitle} mb={false} />
              </div>

              {loading ? (
                <div className="pt-2">
                  <Loading />
                </div>
              ) : (
                <div className="space-y-6">
                  <ResourceList resources={data?.resources} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </PageContent>
  );
};

export default Resources;
